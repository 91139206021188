.main {
  display: flex;
  flex-direction: column;

  .ant-table-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 0;

    .ant-spin-nested-loading {
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 0;

      .ant-spin-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 0;

        .ant-table {
          display: flex;
          flex-direction: column;
          flex: 1;
          height: 0;

          .ant-table-container {
            display: flex;
            flex-direction: column;
            flex: 1;
            height: 0;

            .ant-table-header {
              overflow: revert !important;
            }
          }
        }
      }
    }
  }
}
.main {
  height: 100%;

  .logo {
    height: 32px;
    margin: 16px;
    // background: rgba(255, 255, 255, 0.2);
    
    .name {
      display: block;
      height: 100%;
      width: 100%;
      color: #fff;
      line-height: 32px;
      text-align: center;
      font-size: 25px;
    }
  }

  .userMenu {
    margin-right: 40px;
  }
  
  .site-layout-sub-header-background {
    background: rgb(255, 255, 255);
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  
  .site-layout-background {
    background: #fff;
    height: 100%;
  }
}

